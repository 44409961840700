import React from "react";

import { LoadingContainer } from "@interactive-investor/onestack-web-shared";

import { withAuth } from "../../contexts/Auth";
import { AuthContextInjectedProps } from "../../contexts/Auth/interfaces/context";

export interface AuthPageState {
  mounted: boolean;
}

class AuthPage extends React.Component<
  AuthContextInjectedProps,
  AuthPageState
> {
  constructor(props: AuthContextInjectedProps) {
    super(props);
    this.state = { mounted: false };
  }

  componentDidMount() {
    this.setState({ mounted: true });
  }

  render() {
    return (
      <LoadingContainer
        title="Authentication"
        message="Please wait while we log you out"
        onDidMount={() => {
          if (!this.props.auth.processingLogout) {
            this.props.auth.logout();
          }
          return () => {};
        }}
        {...this.props}
      />
    );
  }
}

export default withAuth(AuthPage);
